import React, { useState } from "react";
import cx from "classnames";
import { IconFont, Button } from "reviewshare-common-component";
import { isMobile } from "lib/common";
import useScroll from "lib/customHooks/useScroll";
import { SERVICE_PDF } from "scheme/link";
import CommonLink from "components/common/CommonLink";
import BizIntroMobileMenu from "components/base/BizIntroMobileMenu";

import "./BizIntroHeader.scss";

import reviewshare_logo from "assets/images/logo/reviewshare_logo.svg";

const BizIntroHeader = ({ fixed, type }) => {
    const scrollY = useScroll(!fixed);
    const [moreMenuStatus, setMoreMenuStatus] = useState(false);

    const active = isMobile() ? scrollY > 111 : scrollY > 72;
    const mobileSubActive = scrollY > 787;

    return (
        <header
            className={cx("biz-intro-header", {
                fixed,
                active,
                "sub-active": mobileSubActive
            })}
        >
            <div className="header-wrapper">
                <a
                    id="biz-intro-header-logo"
                    className="reviewshare-logo"
                    href={process.env.REACT_APP_MAIN_SERVICE_URL}
                >
                    <img src={reviewshare_logo} alt="리뷰쉐어 로고" />
                </a>
                <ul className="button-items">
                    <li className="mobile">
                        <Button
                            to={SERVICE_PDF}
                            className="size-m m-size-xs theme4 square"
                            onClick={() => setMoreMenuStatus(true)}
                        >
                            <IconFont icon="icon_menu">메뉴</IconFont>
                        </Button>
                    </li>
                    {type === "self" && (
                        <li className="web">
                            <CommonLink
                                to={SERVICE_PDF}
                                external
                                className="rs-button size-m theme4"
                            >
                                서비스 소개서 <IconFont icon="icon_download">다운로드</IconFont>
                            </CommonLink>
                        </li>
                    )}
                    <li className="web">
                        <CommonLink to="/" className="rs-button size-m m-size-xs theme4">
                            플랫폼 바로가기
                        </CommonLink>
                    </li>
                    {type === "self" && (
                        <li>
                            <CommonLink to="/signin" className="rs-button size-m m-size-xs theme7">
                                무료로 시작하기
                            </CommonLink>
                        </li>
                    )}
                    {type === "partner" && (
                        <li>
                            <CommonLink
                                to="/bizintro/partner?detail=meeting"
                                className="rs-button size-m m-size-xs theme7"
                                replace
                            >
                                대행 문의하기
                            </CommonLink>
                        </li>
                    )}
                </ul>
            </div>
            {moreMenuStatus && (
                <BizIntroMobileMenu type={type} closeModal={() => setMoreMenuStatus(false)} />
            )}
        </header>
    );
};

export default BizIntroHeader;
